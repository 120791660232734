<template>
  <div class="input-container">
    <b-form-input/>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'
import ApexCharts from 'vue-apexcharts'
export default {
  name: "CellEdit",
  components: {
    BFormInput
  }
}
</script>

<style scoped>

</style>