<template>
  <b-card>
    <div class="tabs-button">
      <b-button-group>
        <b-button
            class="buttons-unit-set"
            v-for="(set, name) in sets"
            :style="{borderBottom: name === currentSet && !tableSets ? '2px solid #4a78cb !important' : ''}"
            :key="name"
            @click="function() {tableSets = false; changesets({setName: name, val: true})}"
        > {{ set.name }}
        </b-button>
        <b-button
            class="buttons-unit-set"
            :style="{borderBottom: tableSets === true ? '2px solid #4a78cb !important' : ''}"
            @click="function() {tableSets = true}"
        > {{ 'Калькулятор' }}
        </b-button>
      </b-button-group>
    </div>
    <div v-if="tableSets" class="calculator-container">
      <div class="control-panel">

      </div>
      <div class="calculator-container">
        <unit-calculator/>
      </div>
    </div>
    <div v-else class="unit-container">
      <div>
        <apexchart
            height="350"
            :options="chartOptions"
            :series="getSeries"
            ref="ordersChart"
        />
      </div>
      <div class="d-flex justify-content-between flex-wrap">
        <sales-region-chart/>
        <stock-chart/>
        <total-values-chart/>
      </div>
      <div class="panel-rule-unit-report d-flex flex-wrap justify-content-between">
        <div class="data-interval d-flex mb-2">
          <div class="date-from mt-1 mr-1 d-flex align-items-center">
            <b-form-datepicker
                id="date-from"
                v-model="dateFrom"
                locale="ru"
                start-weekday="1"
                calendarLocale="ru"
                :label-no-date-selected="'Дата от'"
                @input="inputDateFilterDateFrom"
                :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}"
            />
          </div>
          <div class="date-to mt-1 mr-1 d-flex align-items-center">
            <b-form-datepicker
                id="date-to"
                v-model="dateTo"
                locale="ru"
                calendarLocale="ru"
                start-weekday="1"
                :label-no-date-selected="'Дата до'"
                @input="inputDateFilterDateTo"
                :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}"
            />
          </div>
          <div class="date-to mt-1 mr-1 d-flex align-items-center"
               v-if="currentSet === 'realization'"
          >
            <b-form-select
                class="date-filter-reports unit-filter"
                v-model="currentFilterDate"
                @change="updateByReportId"
                :options="filterDate.map(item=>({text: `#${item.realizationreport_id} от ${item.date_from} до ${item.date_to}`, value: `${item.date_from}to${item.date_to}`}))"
            >
            </b-form-select>
          </div>
          <b-form-checkbox
              class="ml-1"
              style="margin-top: 24px"
              @change="function(val) {filterSurplus = val}"
              :checked="filterSurplus"
          >
            С остатком
          </b-form-checkbox>
          <b-form-checkbox
              class="ml-1"
              style="margin-top: 24px"
              @change="function(val) {filterSales = val}"
              :checked="filterSales"
          >
            С продажами
          </b-form-checkbox>
        </div>
        <div class="d-flex  mb-2">
          <div class="mr-1 mt-1 d-flex align-items-center">
            <b-form-select
                class="unit-filter"
                v-model="filterBrand"
                @change="function(val) { if(val === 'Все') val = ''; filterBrand = val; getByFiltered()}"
                :options="getListBrands"
            >
              <template #first>
                <b-form-select-option :value="null">бренды</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="d-flex mr-1 mt-1 align-items-center">
            <b-form-select
                class="unit-filter"
                v-model="filterObject"
                @change="function(val) {if(val === 'Все') val = ''; filterObject = val; getByFiltered()}"
                :options="getListObjects"
            >
              <template #first>
                <b-form-select-option :value="null">категории</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="d-flex mt-1 align-items-center">
            <b-form-select
                class="unit-filter"
                v-model="filterArticles"
                @change="function(val) {if(val === 'Все') val = ''; filterArticles = val; getByFiltered()}"
                :options="getListArticles"
                placeholder="Артикул"
            >
              <template #first>
                <b-form-select-option :value="null">артикулы</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="d-flex mt-1 ml-1 align-items-center"
               v-if="currentSet === 'orders'"
          >
            <b-form-select
                class="unit-filter"
                v-model="currentWarehouse.warehousename"
                @change="function(val) {if(val === 'Все') val = ''; currentWarehouse.warehousename = val; getByFiltered()}"
                :options="getStockList"
                placeholder="Артикул"
            >
              <template #first>
                <b-form-select-option :value="null">склады</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="d-flex mt-1 ml-1 align-items-center"
               v-if="currentSet === 'orders'"
          >
            <b-form-select
                class="unit-filter"
                v-model="currentRegionname"
                @change="filteredByRegion"
                :options="Object.keys(getRegionName)"
                placeholder="Регионы"
            >
              <template #first>
                <b-form-select-option :value="null">регионы</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="table_unit_report_container">
        <b-table id="table_unit_report"
                 responsive
                 :thead-tr-class="'text-nowrap'"
                 :fields="getFieldsByGroup"
                 first-number
                 show-empty
                 last-number
                 small
                 :details-td-class="'p-0'"
                 :items="getUnitReportRows"
                 ref="table">
          <template #thead-top="data">
            <b-tr>
              <b-th v-for="(item, i) in getMainHeaders"
                    :key="item.hr+i"
                    style="height: 44px"
                    :class="getClassesMainHeader(item)"
                    variant="primary"
                    :colspan="item.val">
                <div class="d-flex align-items-center" style="width: 100%; height: 100%"
                     :class="item.hasOwnProperty('group') ? 'justify-content-between' : 'justify-content-center'">
                  <div v-if="item.hasOwnProperty('group')" style="width: 19px; height: 14px;"></div>
                  <div style="text-transform: none; text-align: center">
                    {{ item.hr || item.label }}
                  </div>
                  <div v-if="item.hasOwnProperty('group')">
                    <feather-icon
                        :icon="item.group ?'MinusSquareIcon' : 'PlusSquareIcon' "
                        class="cursor-pointer"
                        style="margin-left: 5px"
                        @click="function() {setVisibilityFields( {parentField: item.key})}"/>
                  </div>
                </div>
              </b-th>
            </b-tr>
          </template>
          <template #head()="data">
            <div class="d-flex"
                 :class="sortFields.indexOf(data.field.key) !== -1 ? 'justify-content-between' : 'justify-content-center'"
                 :style="{width: data.field.columnWidth}">
              <div style="width: 14px"
                   v-if="sortFields.indexOf(data.field.key) !== -1"
              />
              <div style="text-transform: none; max-width: 105px; white-space: normal; font-weight: lighter">{{
                  data.label
                }}
              </div>
              <sort-icon
                  v-if="sortFields.indexOf(data.field.key) !== -1"
                  @sort="function(val) {getByFiltered({sortDir: val, sortField: data.field.key})}"
              />
            </div>
            <div style="font-weight: normal; margin-top: 5px; text-align: center">
              <div v-if="fulNumFields.indexOf(data.field.key) !== -1">
                <money-format
                    v-if="unitReportCalculateHr[data.column] || +unitReportSum[data.column]"
                    :cost="unitReportCalculateHr[data.column] ? unitReportCalculateHr[data.column]({data, unitReportSum, items: getUnitReportRows}) : +unitReportSum[data.column]"
                />
              </div>
              <div v-else>
                <money-format
                    v-if="unitReportCalculateHr[data.column] || +unitReportSum[data.column]"
                    :cost="Math.round(unitReportCalculateHr[data.column] ? unitReportCalculateHr[data.column]({data, unitReportSum, items: getUnitReportRows}) : +unitReportSum[data.column])"
                />
              </div>
            </div>
          </template>
          <template #head(sub_sort)="data">
            <div class="d-flex flex-column justify-content-between"
                 :style="{width: data.field.columnWidth}">
              <div>
                <div style="padding-left: 4px; font-size: 8px; color: rgb(129 135 147)">
                  Кол-во дней
                </div>
                <b-form-input
                    style="width: 100px"
                    size="sm"
                    v-model="periodSubSort"
                    placeholder="14"
                    @blur="getByFiltered"
                    @keyup.enter="getByFiltered">
                </b-form-input>
              </div>
            </div>
          </template>
          <template #cell()="data">
            <div :style="{width: data.field.columnWidth}"
                 style="text-align: center">
              <money-format
                  :cost="Math.round(unitReportCalculate[data.field.key] ? unitReportCalculate[data.field.key]({row: data.item, unitReportSum}) : (data.value || 0))"
              />
              <span v-if="/_percent$/.test(data.field.key)">%</span>
            </div>
          </template>
          <template #cell(product)="data">
            <div :style="{width: data.field.columnWidth}">
              <product-cell-unit
                  :productData="data.item"
                  @openDetail="function(){tumblerDetail(data.item);}"/>
            </div>
          </template>
          <template #cell(sales_chart)="data">
            <div class="item_chart-main-container"
                 :style="{width: `${(periodLength - 1) * 6 + 10}px`}">
              <div class="d-flex align-items-end item_chart-container_sales item_chart-container"
                   :style="{width: `${(periodLength - 1) * 6}px`}"
              >
                <div class="item_chart item_chart_sales"
                     v-for="(item, name) in realByDays"
                     :key="name + '-' + data.item.nmID"
                     :style="{height: `${((item[data.item.nmID] || 0)/realByDaysMax[data.item.nmID]) * 100}%`}"
                     v-b-popover.hover.top="item[data.item.nmID]"
                     :title="name">
                </div>
              </div>
            </div>
          </template>
          <template #cell(deduction)="data">
            <div :style="{width: data.field.columnWidth}">
              0
            </div>
          </template>
          <template #cell(order_chart)="data">
            <div class="item_chart-main-container"
                 :style="{width: `${(periodLengthOrders - 1) * 6 + 10}px`}">
              <div class="d-flex align-items-end item_chart-container_order  item_chart-container"
                   :style="{width: `${(periodLengthOrders - 1) * 6}px`}">
                <div class="item_chart item_chart_order"
                     v-for="(item, name) in ordersByDays"
                     :key="name + '-' + data.item.nmID"
                     :style="{height: `${((item[data.item.nmID] || 0)/ordersByDaysMax[data.item.nmID]) * 100}%`}"
                     v-b-popover.hover.top="item[data.item.nmID]"
                     :title="name">
                </div>
              </div>
            </div>
          </template>
          <template #cell(forecast)="data">
            <div :style="{width: data.field.columnWidth}">
              <b-form-input
                  v-if="!data.item.is_dimensional"
                  style="width: 100px"
                  size="sm"
                  placeholder=""
                  @keyup.enter="function(val) {addSubSortToOperation({val, ...data.item})}"
                  @input="function(val) {addSubSortToOperation({val, ...data.item})}"
                  v-model="data.item.sub_sort_value"
              ></b-form-input>
              <div v-else>
                {{
                  unitReportDetails.reduce((acc, set) => {
                    if (set[0] && set[0].nmID === data.item.nmID) return set.reduce((acc1, val) => +acc1 + +val.sub_sort_value, 0);
                    return 0
                  }, 0)
                }}
              </div>
            </div>
          </template>
          <template #cell(sub_sort)="data">
            <div :style="{width: data.field.columnWidth}">
             <span style="color: #2d87c2; border-bottom: 1px dashed; cursor: pointer"
                   v-if="!data.item.is_dimensional"
                   @click="function() {
                  setSubSort({nmID: data.item.nmID, barcode: data.item.barcode, value: data.item.sub_sort || 0});
                  addSubSortToOperation({val: data.item.sub_sort || 0 , ...data.item});
                }">
               {{ data.item.sub_sort || 0 }}
             </span>
            </div>
          </template>
          <template #row-details="data">
            <table-unit-details-size
                :nmID="data.item.nmID"
            />
          </template>
        </b-table>
      </div>
      <purchase-list-popup/>
    </div>
  </b-card>
</template>

<script>
import {
  BInputGroup,
  BButton,
  BButtonGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormDatepicker,
  BFormSelectOption,
  BTable,
  VBPopover,
  BCard,
  BAvatar,
  BFormCheckbox,
  BFormSelect,
  BTr,
  BTh
} from 'bootstrap-vue'
import ApexCharts from 'vue-apexcharts'
import SortIcon from "@/components/SortIcon";
import stockChart from "@/components/unitReport/stockChart.vue";
import UnitCalculator from "@/components/unitReport/UnitCalculator.vue";
import salesRegionChart from "@/components/unitReport/salesRegionChart.vue";
import totalValuesChart from "@/components/unitReport/totalValuesChart.vue";
import unitReportCalculate from "@/libs/unitReportCalculate";
import unitReportCalculateHr from "@/libs/unitReportCalculateHr";
import {getToDay} from "@/libs/date";
import PurchaseListPopup from "@/components/procurement/PurchaseListPopup";
import ProductCellUnit from "@/components/ProductCellUnit";
import TableUnitDetailsSize from "@/components/unitReport/TableUnitDetailsSize";
import {mapActions, mapGetters, mapState} from "vuex";

function formatCurrency(value) {
  let val  = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(value);
  return val.split(',')[0]
}


export default {
  components: {
    stockChart,
    UnitCalculator,
    salesRegionChart,
    totalValuesChart,
    BInputGroup,
    apexchart: ApexCharts,
    BInputGroupPrepend,
    BFormSelectOption,
    BFormDatepicker,
    BButton,
    BButtonGroup,
    BFormSelect,
    PurchaseListPopup,
    TableUnitDetailsSize,
    BFormCheckbox,
    ProductCellUnit,
    SortIcon,
    BTable,
    BCard,
    BAvatar,
    BFormInput,
    BTr,
    BTh
  },
  directives: {
    'b-popover': VBPopover
  },
  name: "UnitsReport",
  data() {
    return {
      tableSets: false,
      chartOptions: {

        markers: {
          size: 3,  // Размер маркеров (точек)
          colors: ['#8193e7', '#34c38f', '#f46a6a'],  // Цвет маркеров
          strokeColors: '#fff',  // Цвет обводки маркеров
          strokeWidth: 1,  // Ширина обводки маркеров
          hover: {
            size: 5,  // Размер маркера при наведении
          },
        },
        yaxis: {
          labels: {
            // Используем функцию formatCurrency для форматирования значений на оси Y
            formatter: (val) => formatCurrency(val),
          },
        },
        chart: {
          height: 350,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: [1]
        },
        tooltip: {
          y: {
            // Используем функцию formatCurrency для форматирования значения
            formatter: (val) =>  formatCurrency(val),
          },
          shared: true,
          intersect: false
        },
        colors: ['#8193e7', '#34c38f', '#f46a6a'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.9
          }
        },
        xaxis: {
          categories: []
        }
      },
      stockChartOptions: {
        chart: {
          width: 450,
          type: 'donut',
        },
        labels: [],
        dataLabels: {
          enabled: true
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
        }
      },
      thead: null,
      findText: '',
      sortFields: [
        'quantity',
        'summa',
        'sales_summ',
        'sales_amount',
        'order_summ',
        'order_amount',
        'sales_cost_price',
        'pay_to_finaccount',
        'promo_summa',
        'averagebill',
        'summa_val_value',
        'surplus',
        'delivery',
        'storage_cost',
        'storage_fee_item',
        'cost_acceptance',
        'promo_summa_balance',
        'sales_promo_summa',
        'sales_promo_summa_balance'
      ],
      fulNumFields: [
        'pay_to_finaccount',
        'summa',
        'transfer_amount',
        'delivery',
        'storage_cost',
        'storage_fee_item',
        'penalty',
        'promo_summa_balance',
      ],
      periodSubSort: 14,
      dateFrom: getToDay(-7),
      dateTo: getToDay(),
      filterBrand: null,
      filterObject: null,
      filterArticles: null,
      currentRegionname: null,
      filterSurplus: true,
      filterSales: true,
      currentWarehouse: {
        countryname: null,
        oblastokrugname: null,
        regionname: null,
        warehousename: null,
      },
      currentFilterDate: ``,
    }
  },
  computed: {
    ...mapState('products', ["brands", "vendorCodes", "objects"]),
    ...mapState('unitReportScheme', ["currentSet", "classCell", "classMainTH", "sets", "warehouses", "stock"]),
    ...mapGetters('unitCalculator', ["getFieldsCalculator"]),
    ...mapState('unitReport', ["salesByRegions", "unitReportDetails", "listFieldsByGroup", "unitReportRows", "unitReportSum", "filterDate", "realByDays", "ordersByDays", "ordersByDaysMax", "realByDaysMax", "periodLengthOrders", "periodLength"]),
    ...mapGetters('unitReport', ["getLengthsDetails"]),
    ...mapGetters('unitReportScheme', ["getFieldsByGroup", "getMainHeaders"]),
    getListBrands() {
      return this.brands
    },
    getSeries() {
      const orders = {
        name: 'Заказы',
        data: []
      };

      const sales = {
        name: 'Продажи',
        data: []
      };

      const promo = {
        name: 'Расходы на рекламу',
        data: []
      }

      let orderItems = Object.values(this.ordersByDays);
      if (orderItems.length) orders.data = orderItems.map(item => {
        let values = Object.values(item)
        if (values.length) return values.reduce((agg, sum) => agg + Math.round(+sum), 0)
        return 0
      });
      let realByDays = Object.values(this.realByDays);
      if (realByDays.length) sales.data = realByDays.map(item => {
        let values = Object.values(item)
        if (values.length) return values.reduce((agg, sum) => agg + Math.round(+sum), 0)
        return 0
      });

      let result = [
        orders,
        sales
      ];

      if (promo.data.length) {
        result.push(promo)
      }

      return result
    },
    getDaysByPeriod() {
      const days = Object.keys(this.ordersByDays)
      if (days.length) {
        return days.map(day => {
          let item = day.split('-');
          return `${item[1]}.${item[2]}`
        })
      }
      return []
    },
    unitReportCalculate() {
      return unitReportCalculate
    },
    unitReportCalculateHr() {
      return unitReportCalculateHr
    },
    getStockList() {
      return this.stock.map(item => item.warehousename)
    },
    getCountryOrderList() {
      let res = {}
      this.warehouses.forEach(item => res[item.countryname] = 1);
      return Object.keys(res)
    },
    getRegionName() {
      let res = {}
      this.warehouses.forEach(item => res[item.oblastokrugname || item.countryname] = {
        oblastokrugname: item.oblastokrugname,
        countryname: item.countryname
      });
      return res
    },
    getListWarehouses() {
      if (this.currentWarehouse.warehousename) return [this.currentWarehouse.warehousename];
    },
    getCountryOrder() {
      if (this.currentWarehouse.countryname) return [this.currentWarehouse.countryname];
    },
    getRegionOrder() {
      if (this.currentWarehouse.oblastokrugname) return [this.currentWarehouse.oblastokrugname];
    },
    getListObjects() {
      return this.objects
    },
    getListArticles() {
      return this.vendorCodes
    },
    getUnitReportRows() {
      let reportRows = this.unitReportRows
      if (this.filterSurplus) reportRows = reportRows.filter(item => item.surplus > 0);
      if (this.filterSales) reportRows = reportRows.filter(item => item.sales_amount > 0 || item.order_amount > 0);
      return reportRows;
    }
  },
  methods: {
    ...mapActions('unitReport', ["getSalesByRegions", "clearSubSortToOperation", "addSubSortToOperation", "getUnitReport", 'addSubSort', "getUnitReportDetail", "getDatesReports", "addRows", "delRows", "getRealReportByDay", "getOrdersByDay"]),
    ...mapActions('unitReportScheme', ["setVisibilityFields", "getWarehouses", "changesets", "getStock"]),
    ...mapActions('purchase', ["loadListPurchase"]),

    getClassesMainHeader(item) {
      let classes = ['main-header', `main-header-${item.key}`]

      if (item.key === 'product') {
        classes.push('b-table-sticky-column');
      }

      return classes
    },

    getPromoUnit(data) {
      if (data.item.quantity > 0) {
        return ((+data.item.promo_summa + +data.item.promo_summa_balance) / data.item.quantity).toFixed(2).replace('.', ',');
      }
      return 0
    },

    /*    handleWindowScroll() {
          if (window.scrollY > 0) {
            this.thead.style.top = `${window.scrollY - 200}px`;
          } else {
            this.thead.style.position = '';
            this.thead.style.top = '';
            this.thead.style.width = '';
          }
        },*/

    async filteredByRegion(val) {
      this.currentWarehouse.countryname = this.getRegionName[val]?.countryname
      this.currentWarehouse.oblastokrugname = this.getRegionName[val]?.oblastokrugname
      await this.getByFiltered()
    },

    tumblerDetail(item) {
      item._showDetails = !item._showDetails;
      //let exist = this.unitReportDetails.some(detailSet => detailSet.some(size => +size.nmID === +item.nmID));
      //if (!exist) {
      this.addRows({
        nmID: item.nmID,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        brand: this.filterBrand,
        object: this.filterObject,
        vendorCode: this.filterArticles,
        sortDir: item._sortDir,
        sortField: item._sortField,
        periodSubSort: this.periodSubSort,
      });
      // }
    },

    inputDateFilterDateFrom(value) {
      this.dateFrom = value
      this.getByFiltered()
    },

    inputDateFilterDateTo(value) {
      this.dateTo = value
      this.getByFiltered()
    },

    async updateByReportId(value) {
      let [dateFrom, dateTo] = value.split('to');
      this.dateTo = dateTo;
      this.dateFrom = dateFrom;
      await this.getByFiltered();
    },

    async setSubSort({nmID, barcode, value}) {
      await this.addSubSort({nmID, barcode, value})
    },

    async getByFiltered(params = {sortDir: '', sortField: ''}) {
      await this.getUnitReport({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        brand: this.filterBrand,
        object: this.filterObject,
        vendorCode: this.filterArticles,
        sortDir: params.sortDir,
        sortField: params.sortField,
        periodSubSort: this.periodSubSort,
        warehosesNames: this.getListWarehouses,
        countryName: this.getCountryOrder,
        regionname: this.getRegionOrder,
      });
      await this.getSalesByRegions({dateFrom: this.dateFrom, dateTo: this.dateTo});
    },
  },
  watch: {
    ordersByDays(values) {
      const days = Object.keys(values);
      if (days.length && this.$refs.ordersChart) {
        this.$refs.ordersChart.updateOptions({
          xaxis: {
            categories: days.map(day => {
              let item = day.split('-');
              return `${item[2]}.${item[1]}`
            })
          }
        })

      }
    },
  },
  async mounted() {
    // this.thead = this.$refs.table.$el.querySelector('#table_unit_report thead');
    await this.getStock();
    await this.getDatesReports();
    await this.getByFiltered();
    this.currentFilterDate = `${this.filterDate[0]?.date_from}to${this.filterDate[0]?.date_to}`;
    await this.getWarehouses({dateFrom: this.dateFrom, dateTo: this.dateTo});
    // window.addEventListener('scroll', this.handleWindowScroll);
    // await this.loadRating();
    // this.loadListPurchase();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  },
}
</script>

<style scoped>

.forecast-container {
  width: 150px;
}


.unit-filter {
  width: 160px;
}


.item_chart {
  width: 5px;
  min-width: 5px;
  margin-right: 1px;
}

.item_chart_sales {
  background-color: #7addbf;
}

.buttons-unit-set {
  background-color: #ffffff !important;
  border: none;
  outline: none;
  color: #4a78cb !important;
  border-radius: 0;
}

.item_chart-container_sales {
  border-bottom: 1px solid #62a38d;
}

.item_chart_order {
  background-color: #8dafe6;
}

.item_chart-container_order {
  border-bottom: 1px solid #5d84b9;
}

.btn-detail-cont {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.item_chart-container {
  margin: 20px 0;
  height: 40px;
  overflow-x: hidden;
}

.item_chart-main-container {
  display: flex;
  align-items: end;
}

.card-contact {
  flex: 1 1 auto;
  padding: 1.5rem;
  background-color: rgba(186, 191, 199, .12);
  margin-bottom: 2rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
}
</style>