<template>
  <div class="mt-2">
    <b-table id="table_unit-calculator"
             :fields="getFieldsCalculator"
             :items="productCalculatesList"
             small
             responsive
             ref="table">
      <template #thead-top="data">
        <b-tr>
          <b-th v-for="(item, i) in getMainHeaders"
                :class="`main_${item.name}`"
                :key="item.name+i"
                style="height: 44px"
                variant="primary"
                :colspan="item.childNum">
            <div class="d-flex align-items-center" style="width: 100%; height: 100%"
                 :style="{height: '53px'}"
                 :class="item.hasOwnProperty('group') ? 'justify-content-between' : 'justify-content-center'">
              <div v-if="item.hasOwnProperty('group')" style="width: 19px; height: 14px;"></div>
              <div style="text-transform: none; text-align: center">
                {{ item.mainHeaderName }}
              </div>
              <div v-if="item.hasOwnProperty('group')">
                <feather-icon
                    :icon="item.group ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                    class="cursor-pointer"
                    style="margin-left: 5px"
                    @click="function() {setVisibilityFields( {parentField: item.key})}"
                />
              </div>
            </div>
          </b-th>
        </b-tr>
      </template>
      <template #cell(product)="data">
        <div :style="{width: data.field.columnWidth}">
          <product-cell-unit
              :productData="data.item"
              :details="false"/>
        </div>
      </template>
      <template #cell()="data">
        <div class="cell-input-container d-flex align-items-center justify-content-center">
          <b-form-input
              v-if="calcFormulas[data.field.key] && data.field.typeCell === 'input'"
              :style="data.field.columnWidth ? {width: data.field.columnWidth} : ''"
              class="cell-input"
              type="number"
              :value="data.value"
              @input="function(val){inputHandling({row: data.item, val, fieldName: data.field.key})}"
          />
          <div v-else-if="calcFormulas[data.field.key]"
               :style="data.field.columnWidth ? {width: data.field.columnWidth} : ''"
          >
            <money-format :cost="calcFormulas[data.field.key]({row: data.item})"/>
          </div>
          <div v-else
               :style="data.field.columnWidth ? {width: data.field.columnWidth} : ''"
          >
            <money-format :cost="data.value"/>
          </div>
        </div>
      </template>

      <template #cell(final_price)="data">
        <div class="d-flex justify-content-center align-items-center">
          <div class="final_price-container d-flex align-items-center">
            <div class="summa-value_container d-flex justify-content-between align-items-center">
              <money-format
                  :cost="Math.round(data.item.discounted_price - (data.item.discounted_price  * data.item.discount_spp / 100))"
              />
              <b-form-input class="mx-2 cell-input"
                            type="number"
                            :value="data.value"
                            @input="function(val){inputHandling({row: data.item, val, fieldName: 'final_price'})}"
              />
            </div>
            <div style="color: #d87a68; font-size: 12px" class="text-left">
              <money-format
                  :cost="Math.round((data.value - (data.value / 100 * data.item.discount_spp)) - data.value)"
              />
              <div class="d-flex">
                <div>
                  {{
                    +data.item.discount_spp !== 0 && +data.value !== 0 ? Math.round(((+data.value - (+data.value / 100 * +data.item.discount_spp)) - +data.value) / +data.value * 100) : 0
                  }}
                </div>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #cell(seller_price)="data">
        <div class="d-flex justify-content-center align-items-center">
          <div class="final_price-container d-flex align-items-center">
            <div class="summa-value_container d-flex justify-content-between align-items-center">
              <money-format
                  :cost="Math.round(data.item.discounted_price)"
              />
              <b-form-input class="mx-2 cell-input"
                            type="number"
                            :value="data.value"
                            @input="function(val){inputHandling({row: data.item, val, fieldName: 'seller_price'})}"
              />
            </div>

            <div style="color: #d87a68; font-size: 12px" class="text-left">
              <money-format
                  :cost="Math.round((data.value - (data.value / 100 * data.item.discount_spp)) - data.value)"
              />
              <div class="d-flex">
                <div>
                  {{
                    +data.item.discount_spp !== 0 && +data.value !== 0 ? Math.round(((+data.value - (+data.value / 100 * +data.item.discount_spp)) - +data.value) / +data.value * 100) : 0
                  }}
                </div>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #cell(first_price)="data">
        <div class="d-flex justify-content-center align-items-center">
          <div class="final_price-container d-flex align-items-center">
            <div class="summa-value_container d-flex justify-content-between align-items-center">
              <money-format
                  :cost="data.item.price"
              />
              <b-form-input class="mx-2 cell-input"
                            type="number"
                            :value="data.value"
                            @input="function(val){inputHandling({row: data.item, val, fieldName: 'first_price'})}"
              />
            </div>
            <div style="color: #d87a68; font-size: 12px" class="text-left">
              <money-format
                  :cost="Math.round((data.value - (data.value / 100 * data.item.discount_spp)) - data.value)"
              />
              <div class="d-flex">
                <div>
                  {{
                    +data.item.discount_spp !== 0 && +data.value !== 0 ? Math.round(((+data.value - (+data.value / 100 * +data.item.discount_spp)) - +data.value) / +data.value * 100) : 0
                  }}
                </div>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #cell(discount_spp)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-input class="mx-1 cell-input"
                        :style="{width: '40px'}"
                        type="number"
                        :value="data.value"
                        @input="function(val){inputHandling({row: data.item, val, fieldName: 'discount_spp'})}"
          />
          %
        </div>
      </template>

      <template #cell(variant_delivery)="data">
        <b-dropdown id="variants-delivery"
                    variant="primary"
                    size="sm"
                    :text="data.value">
          <b-dropdown-item
              v-for="variant of variantsDelivery"
              :key="variant.name"
              @click="data.item.variant_delivery = variant.name"
          >
            <b-badge :variant="variant.color">
              {{ variant.label }}
            </b-badge>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(warehouse)="data">
        <b-dropdown id="variants-delivery"
                    variant="primary"
                    size="sm"
                    :text="data.value">
          <div style="max-height: 300px; overflow: auto">
            <b-dropdown-item
                v-for="war of warehousesTariff"
                :key="war.warehouse_name"
                @click="function() {
                  data.item.warehouse = war.warehouse_name
                  data.item.box_delivery_and_storage_expr = war.box_delivery_and_storage_expr
                  data.item.box_delivery_base = war.box_delivery_base
                  data.item.box_delivery_liter = war.box_delivery_liter
                  data.item.box_storage_base = war.box_storage_base
                  data.item.box_storage_liter = war.box_storage_liter
                }"
            >
              {{ war.warehouse_name }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import ProductCellUnit from "@/components/ProductCellUnit";
import CalculateCell from "@/components/UnitCalculatar/CalculateCell";
import CellEdit from "@/components/CellEdit.vue";
import calculationFormulas from "@/libs/calculationFormulas";


import {
  BTable,
  BFormInput,
  BFormTextarea,
  BFormSelectOption,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BBadge,
  BTr,
  BTh
} from 'bootstrap-vue'

export default {
  name: "UnitCalculator",


  data() {
    return {
      currentVariantDelivery: 'fbo',
      variantsDelivery: [
        {
          name: 'fbo',
          label: 'FBO',
          color: 'light-secondary'
        },
        {
          name: 'fbs',
          label: 'FBS',
          color: 'light-success'
        },
        {
          name: 'dbs',
          label: 'DBS',
          color: 'light-danger'
        },
        {
          name: 'express',
          label: 'EXPRESS',
          color: 'light-danger'
        },
      ],
      productCalculatesList: [],
      calcFormulas: {},
      currentCalcRow: {
        _showDetails: null,
        priceByApi: null,
        imgMain: null,
        subjectName: null,
        nmID: null,
        vendorCode: null,
        feedbacksCount: null,
        seller_price: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        title: null,
        price: null,
        discounted_price: null,
        valuation: null,
        sales_plan: null,
        final_price: null,
        amount_sales: null,
        discount_spp: null,
        first_price: null,
        purchase_price_total: null,
        purchase_price_unit: null,
        delivery_calc_total: null,
        delivery_calc_unit: null,
        prepend_total: null,
        prepend_unit: null,
        cost_price: null,
        cost_price_unit: null,
        cost_price_percent: null,
        dues: null,
        promo_total: null,
        promo_unit: null,
        promo_percent: null,
        variant_delivery: null,
        kgvp_marketplace: null,
        kgvp_supplier: null,
        kgvp_supplier_express: null,
        paid_storage_kgvp: null,
        parent_id: null,
        wb_commission_percent: null,
        wb_commission_unit: null,
        warehouse: null,
        receipt_part: null,
        receipt_unit: null,
        receipt_percent: null,
        val_value_part: null,
        val_value_unit: null,
        val_value_percent: null,
        box_delivery_and_storage_expr: null,
        box_delivery_base: null,
        box_delivery_liter: null,
        box_storage_base: null,
        product_turnover: null,
        dues_unit: null,
        volume: null
      }
    }
  },

  components: {
    ProductCellUnit,
    CalculateCell,
    BTable,
    BBadge,
    BFormSelectOption,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CellEdit,
    BFormInput,
    BFormTextarea,
    BTr,
    BTh
  },
  computed: {
    ...mapState('unitReport', ["unitReportRows", "unitReportSum"]),
    ...mapState('unitCalculator', ["warehousesTariff"]),
    ...mapGetters('unitCalculator', ["getFieldsCalculator", "getMainHeaders"]),
  },

  methods: {
    ...mapActions("unitCalculator", ["createCalculate", "loadCalculates", "updateListProductCalculate", "setVisibilityFields"]),
    inputFinishPrice({data, val}) {
      data.item[data.field.key] = +val
    },
    getCalculationFormulas() {
      return calculationFormulas
    },

    inputHandling({row, fieldName, val}) {
      calculationFormulas[fieldName]({row, val});
      this.saveRowCalc({row});
    },

    async saveRowCalc({row}) {
      await this.createCalculate(row)
    },
  },

  watch: {},

  async beforeMount() {
    this.calcFormulas = calculationFormulas
    this.productCalculatesList = await this.loadCalculates()
    await this.updateListProductCalculate();
  }
};
</script>

<style>

#table_unit-calculator td {
  padding: 0 5px;
  text-align: center;
}

#table_unit-calculator th {
  text-transform: none;
  background-color: white;
  border: none;
  text-align: center;
  border-top: 1px solid #ebe9f1;
}

#table_unit-calculator th:first-child {
  position: sticky;
  left: 0;
  width: 250px;
  background-color: #f8f8f8;
}

.cell-input {
  padding: 0 4px;
  width: 70px;
  height: 30px;
}

#table_unit-calculator thead tr:nth-child(1) th {
  background-color: #A2C0F0;
}

#table_unit-calculator thead tr:nth-child(2) th {
  background-color: #DFEAFB;
}

#table_unit-calculator td.b-table-sticky-column {
  background-color: #eff5ff !important;
}

.cell-input-container {
  margin: 0 5px;
}

.main_promo_summa,
.main_promo_summa_balance,
.main_promo {
  background-color: #ecc3bb !important;
}

.sub_header_promo,
.sub_header_promo_summa,
.sub_header_promo_summa_balance {
  background-color: #f5eae9 !important;
}

.main_receipt,
.main_val_value,
.main_final_price,
.main_discount_spp,
.main_seller_price,
.main_first_price {
  background-color: #88d2ae !important;
}

.sub_header_receipt,
.sub_header_val_value,
.sub_header_final_price,
.sub_header_discount_spp,
.sub_header_seller_price,
.sub_header_first_price {
  background-color: #e0f8ec !important;
}

.calc_price_cell {
  background-color: #f8fffc !important;
}

.main_expenses_mp,
.main_variant_delivery,
.main_commission,
.main_wb_commission_percent,
.main_warehouse,
.main_ransom_percent,
.main_logistics_tariff,
.main_logistics_with_ransom,
.main_storage_per_day,
.main_storage_over_turnover,
.main_product_turnover,
.main_volume,
.main_weight,
.main_length,
.main_width,
.main_height {
  background-color: #B5A6D4 !important;
}

.sub_header_expenses_mp,
.sub_header_variant_delivery,
.sub_header_commission,
.sub_header_wb_commission_percent,
.sub_header_warehouse,
.sub_header_ransom_percent,
.sub_header_logistics_tariff,
.sub_header_logistics_with_ransom,
.sub_header_storage_per_day,
.sub_header_storage_over_turnover,
.sub_header_product_turnover,
.sub_header_volume,
.sub_header_weight,
.sub_header_length,
.sub_header_width,
.sub_header_height {
  background-color: #e4dff0 !important;
}

.white_blue {
  background-color: #F9FBFF !important;
}
</style>


