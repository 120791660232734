<template>
  <b-modal
      id="sub_sort_to_purchase"
      hide-header
      hide-footer
      size="lg"
      centered
      v-model="show"
      @hide=""
  >
    <h5>
      Добавить к закупке или создать новую
    </h5>
    <b-list-group>
      <b-list-group-item variant="secondary" class="cursor-pointer"
                         @click="createAddFromSubSortToPurchase">
        <div class="w-100 d-flex justify-content-between">
          <div> Создать закупку</div>
          <feather-icon icon="PlusCircleIcon" size="22"/>
        </div>
      </b-list-group-item>
      <b-list-group-item
          v-for="(purchase, i) in purchases"
          :key="i"
          @click="function(){addFromSubSortToPurchase(purchase.id)}">
        <div class="w-100 d-flex justify-content-between">
          <div>{{ purchase.name }}</div>
          <b-badge variant="primary" pill>{{ purchase.products.length }}</b-badge>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {
  BListGroup,
  BBadge,
  BListGroupItem
} from "bootstrap-vue";

export default {
  components: {
    BListGroup,
    BBadge,
    BListGroupItem,
  },
  name: "PurchaseListPopup",
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapState('purchaseItem', ["productsToAdd", "products"]),
    ...mapState('purchase', ["purchases"]),
    ...mapState('unitReport', ["subSortToOperation"]),
  },

  methods: {
    addProductToPurchase(id) {
      const products = this.subSortToOperation.map(prod=>({
        nmId: prod.nmID,
        chrtID: prod.chrtId,
        amount: prod.sub_sort_value
      }));
      this.prependProducts({purchaseId: id, products, isAmountAdd: true});
      this.clearSubSortToOperation();
    },

    async addFromSubSortToPurchase(id) {
      await this.addProductToPurchase(id);
      this.$router.push(`/procurement/${id}`);
    },

    async createAddFromSubSortToPurchase(id) {
      this.$router.push(`/procurement`);
    },

    ...mapActions('purchaseItem', ["prependProducts", "setProductsToAdd", "clearProductsToAdd"]),
    ...mapActions('unitReport', ["clearSubSortToOperation"])
  }
}
</script>

<style scoped>

</style>