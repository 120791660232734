<template>
  <div
      @click="change"
      class="cursor-pointer"
  >
    <feather-icon icon="ChevronDownIcon"
                  v-if="state"
    />
    <feather-icon icon="ChevronUpIcon"
                  v-else
    />
  </div>
</template>

<script>
export default {
  name: "SortIcon",
  data() {
    return {
      state: true
    }
  },

  methods: {
    change() {
      let r = this.state ? 'desc' : 'asc'
      this.state = !this.state
      this.$emit('sort', r);
    }
  }
}


</script>

<style scoped>

</style>