import { render, staticRenderFns } from "./totalValuesChart.vue?vue&type=template&id=3e99c480&scoped=true&"
import script from "./totalValuesChart.vue?vue&type=script&lang=js&"
export * from "./totalValuesChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e99c480",
  null
  
)

export default component.exports