<template>
  <div class="product-cell d-flex position-relative align-items-center text-left">
    <div v-if="productData.is_dimensional && details"
         class="btn-detail-cont cursor-pointer mr-1"
         @click="openDetail()">
      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-if="productData._showDetails === true"
      >
        <path d="M15.8213 1.07129L8.92044 7.87056C8.88191 7.90853 8.82017 7.90897 8.7811 7.87156L1.67915 1.07129"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-else>
        <path d="M0.75 1L7.54927 7.90085C7.58724 7.93938 7.58768 8.00112 7.55027 8.04019L0.75 15.1421"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
    </div>
    <div v-else class="mr-1" style="min-width: 20px">
    </div>
    <div class="product-img mr-1">
      <img style="width: 60px" :src="productData.imgMain" :alt="productData.subjectName">
    </div>
    <div>
      <div style="font-size: 13px; color: #1e1e1e" :title="productData.title" class="product-cell-unit text-nowrap">
        {{ productData.title }}
      </div>
      <div style="font-size: 12px" class="d-flex nmid-card">
        <svg width="18" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2327_4)">
            <path
                d="M24 0H6C2.68629 0 0 2.68629 0 6V24C0 27.3137 2.68629 30 6 30H24C27.3137 30 30 27.3137 30 24V6C30 2.68629 27.3137 0 24 0Z"
                fill="url(#paint0_linear_2327_4)"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3 10.5L5.827 20.923L7.96 20.921L10.22 13.136L12.479 20.919L14.629 20.922L17.456 10.5L15.601 10.503L13.419 18.548L11.083 10.501L9.354 10.5L7.026 18.506L4.855 10.5H3ZM25.546 20.07C26.166 19.504 26.471 18.796 26.471 17.943C26.471 16.861 25.911 15.996 24.986 15.516C25.692 14.999 26.057 14.303 26.057 13.389C26.057 12.573 25.765 11.888 25.156 11.335C24.559 10.795 23.829 10.506 22.964 10.506H18.776V20.922H23.293C24.194 20.922 24.925 20.635 25.546 20.07ZM20.517 14.855V12.116H22.977C23.744 12.116 24.328 12.716 24.328 13.486C24.328 14.254 23.744 14.856 22.976 14.856H20.517V14.855ZM24.742 17.859C24.7451 18.0493 24.7101 18.2383 24.6392 18.4149C24.5682 18.5916 24.4628 18.7522 24.3289 18.8876C24.1951 19.0229 24.0356 19.1301 23.8598 19.203C23.6839 19.2759 23.4953 19.313 23.305 19.312V19.324H20.517V16.404H23.305C23.4955 16.403 23.6843 16.4402 23.8602 16.5132C24.0362 16.5862 24.1958 16.6936 24.3297 16.8291C24.4635 16.9647 24.569 17.1256 24.6398 17.3025C24.7106 17.4793 24.7453 17.6685 24.742 17.859Z"
                  fill="white"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_2327_4" x1="30" y1="0" x2="0" y2="30" gradientUnits="userSpaceOnUse">
              <stop stop-color="#491174"/>
              <stop offset="1" stop-color="#CC10AC"/>
            </linearGradient>
            <clipPath id="clip0_2327_4">
              <rect width="30" height="30" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <a style="margin-left: 5px" target="_blank"
           :href="`https://www.wildberries.ru/catalog/${productData.nmID}/detail.aspx`">
          <div class="nm-id_small">
            {{ productData.nmID }}
          </div>
        </a>
        <div :title="productData.vendorCode" style="margin-left: 5px; max-width: 58px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ productData.vendorCode }}</div>
      </div>
      <div style="font-size: 12px">
        <div class="d-flex align-items-center">
          <feather-icon class="icon-unit-report-prod" icon="StarIcon" fill="#FFB432" color="#FFB432"/>
          <span style="color: #1e1e1e; font-size: 13px" class="feedbacks-count">{{ productData.valuation }}</span>
          <span v-if="productData.feedbacksCount" class="feedbacks-count">{{ productData.feedbacksCount }} отзывов</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCellUnit",
  props: {
    productData: Object,
    details: {
      default() {
        return true
      },
      type: Boolean
    }
  },
  methods: {
    openDetail() {
      this.$emit('openDetail');
    },
  }
}
</script>

<style scoped>

.product-cell-unit {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}


.nm-id_small {
  font-size: 12px;
}

.btn-detail-cont {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.feedbacks-count {
  margin-right: 5px;
}

.nmid-card {
  margin: 5px 0;
}

.icon-unit-report-prod {
  margin-right: 5px;
}
</style>