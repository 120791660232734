module.exports = {
    averageBillOrders({unitReportSum}) {
        return unitReportSum.sales_amount ? unitReportSum.sales_summ / unitReportSum.sales_amount : 0;
    },
    sales_cost_price_unit({unitReportSum}) {
        return unitReportSum.sales_amount ? unitReportSum.sales_cost_price / unitReportSum.sales_amount : 0;
    },
    sales_cost_price_percent({unitReportSum}) {
        return unitReportSum.sales_amount ? unitReportSum.sales_cost_price / unitReportSum.sales_summ * 100 : 0;
    },
    cost_price_unit({unitReportSum}) {
        return unitReportSum.quantity ? unitReportSum.cost_price / unitReportSum.quantity : 0;
    },
    cost_price_percent({unitReportSum}) {
        return unitReportSum.summa ? unitReportSum.cost_price / unitReportSum.summa * 100 : 0;
    },
    promo_total({unitReportSum}) {
        return +unitReportSum.promo_summa + +unitReportSum.promo_summa_balance;
    },
    sales_promo_total({unitReportSum}) {
        return +unitReportSum.sales_promo_summa + +unitReportSum.sales_promo_summa_balance;
    },
    sales_promo_unit({unitReportSum}) {
        return (+unitReportSum.sales_promo_summa_balance + +unitReportSum.sales_promo_summa) / unitReportSum.sales_amount;
    },
    promo_unit({unitReportSum}) {
        return +unitReportSum.quantity !== 0 ? (+unitReportSum.promo_summa + +unitReportSum.promo_summa_balance) / unitReportSum.quantity : 0;
    },
    sales_promo_percent({unitReportSum}) {
        return +unitReportSum.sales_summ !== 0 ? (+unitReportSum.sales_promo_summa_balance + +unitReportSum.sales_promo_summa) / unitReportSum.sales_summ * 100 : 0;
    },
    promo_percent({unitReportSum}) {
        return +unitReportSum.summa !== 0 ? (+unitReportSum.promo_summa + +unitReportSum.promo_summa_balance) / unitReportSum.summa * 100 : 0;
    },
    unit_val_value({unitReportSum}) {
        return +unitReportSum.summa !== 0 ? unitReportSum.summa_val_value / unitReportSum.quantity : 0;
    },
    pay_to_finaccount({unitReportSum, items}) {
        return items[0] ? unitReportSum.pay_to_finaccount - (items[0].deduction_orig - unitReportSum.promo_summa_balance) : 0
    },
    deduction({unitReportSum, items}) {
        return items[0] ? items[0].deduction_orig - unitReportSum.promo_summa_balance : 0
    },
    val_value_percent({unitReportSum}) {
        return +unitReportSum.summa !== 0 ? unitReportSum.summa_val_value / unitReportSum.summa * 100 : 0;
    },
    expenses_mp_sum({unitReportSum}) {
        return (+unitReportSum.delivery || 0) +
            (+unitReportSum.delivery_unit || 0) +
            (+unitReportSum.penalty || 0) +
            (-unitReportSum.additional_payment || 0) +
            (+unitReportSum.storage_cost || 0) +
            (+unitReportSum.storage_fee_item || 0) +
            (+unitReportSum.cost_acceptance || 0)
    },
    expenses_mp_unit({unitReportSum}) {
        return +unitReportSum.quantity !== 0 ? ((+unitReportSum.delivery || 0) +
            (+unitReportSum.delivery_unit || 0) +
            (+unitReportSum.penalty || 0) +
            (-unitReportSum.additional_payment || 0) +
            (+unitReportSum.storage_cost || 0) +
            (+unitReportSum.storage_fee_item || 0) +
            (+unitReportSum.cost_acceptance || 0)) / unitReportSum.quantity : 0
    },
    expenses_mp_percent({unitReportSum}) {
        return +unitReportSum.summa !== 0 ? ((+unitReportSum.delivery || 0) +
            (+unitReportSum.delivery_unit || 0) +
            (+unitReportSum.penalty || 0) +
            (-unitReportSum.additional_payment || 0) +
            (+unitReportSum.storage_cost || 0) +
            (+unitReportSum.storage_fee_item || 0) +
            (+unitReportSum.cost_acceptance || 0)) / unitReportSum.summa * 100 : 0
    },
    delivery_unit({unitReportSum}) {
        return +unitReportSum.quantity !== 0 ? unitReportSum.delivery / unitReportSum.quantity : 0
    },
}
