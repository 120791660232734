<template>
  <div class="calc-container d-flex justify-content-between">
    <div class="calc-input-container d-flex align-items-center">
      <div class="result-diff-container">
        <money-format
            :cost="dataCell.value"
        />
      </div>
      <div class="result-diff-container">
        <money-format
            :cost="dataCell.value - (dataCell.value / 100 * dataCell.item.discount_spp)"
        />
      </div>
    </div>
    <div class="diff-container">
      <div class="h-50 d-flex align-items-center justify-content-center">
        <money-format
            style="color: #b75555"
            :cost="Math.round((dataCell.value - (dataCell.value / 100 * dataCell.item.discount_spp)) - dataCell.value)"
        />
      </div>
      <div class="h-50  d-flex align-items-center justify-content-center">
        <money-format
            style="color: #b75555"
            :cost="Math.round(((dataCell.value - (dataCell.value / 100 * dataCell.item.discount_spp)) - dataCell.value) / dataCell.value * 100)"
        />
        %
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'

export default {
  name: "CalculateCell",
  data() {
    return {
      currentItem: null
    }
  },
  components: {
    BFormInput,
  },
  props: {
    dataCell: {
      default() {
        return {}
      },
      type: Object
    }
  }
}
</script>


<style>


.calc-input {
}

.calc-input:focus {
  box-shadow: none !important;
}

.calc-input:active {
  box-shadow: none !important;
}

.result-diff-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calc-container {
  height: 82px;
  overflow: hidden;
  border-left: 1px solid #ebe9f1;
}

.calc-input-container {
  height: 100%;
  width: 65%;
  border-right: 1px solid #ebe9f1;
}

.diff-container {
  height: 100%;
  width: 35%;
}

.diff-container div:first-child {
  border-bottom: 1px solid #ebe9f1;
}


</style>


