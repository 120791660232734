module.exports = {
    order_percent({row, unitReportSum}) {
        return +unitReportSum.order_summ !== 0 ? row.order_summ / unitReportSum.order_summ * 100 : 0
    },
    unit_val_value({row}) {
        return +row.quantity ? (row.summa_val_value / (+row.quantity)) : 0
    },
    val_value_percent({row}) {
        return +row.summa !== 0 ? (row.summa_val_value / row.summa * 100) : 0
    },
    sales_percent({row, unitReportSum}) {
        return +unitReportSum.sales_summ !== 0 ? row.sales_summ / unitReportSum.sales_summ * 100 : 0
    },
    expenses_mp_sum({row}) {
        return (+row.delivery || 0) +
            (+row.delivery_unit || 0) +
            (+row.penalty || 0) +
            (-row.additional_payment || 0) +
            (+row.storage_cost || 0) +
            (+row.storage_fee_item || 0) +
            (+row.cost_acceptance || 0)

    },
    sales_cost_price_percent({row}) {
        return +row.sales_summ !== 0 ? row.sales_cost_price / row.sales_summ * 100 : 0
    },
    cost_price_percent({row}) {
        return +row.summa !== 0 ? row.cost_price / row.summa * 100 : 0
    },
    cost_price_unit({row}) {
        return +row.quantity !== 0 ? (row.cost_price / (+row.quantity)) : 0
    },
    sales_cost_price_unit({row}) {
        return +row.sales_amount !== 0 ? (row.sales_cost_price / (+row.sales_amount)) : 0
    },
    sales_promo_total({row}) {
        return +row.sales_promo_summa + +row.sales_promo_summa_balance
    },
    promo_total({row}) {
        return +row.promo_summa + +row.promo_summa_balance
    },
    sales_promo_unit({row}) {
        return +row.order_amount !== 0 ? (+row.sales_promo_summa_balance + +row.sales_promo_summa) / row.order_amount : 0
    },
    promo_unit({row}) {
        return +row.quantity !== 0 ? (+row.promo_summa_balance + +row.promo_summa) / row.quantity : 0
    },
    sales_promo_percent({row}) {
        return typeof +row.sales_summ === "number" && +row.sales_summ !== 0 && (+row.sales_promo_summa + +row.sales_promo_summa_balance) !== 0 ? +(+row.sales_promo_summa + +row.sales_promo_summa_balance) / row.sales_summ * 100 : 0
    },
    promo_percent({row}) {
        return typeof +row.summa === "number" && +row.summa !== 0 && (+row.promo_summa + +row.promo_summa_balance) !== 0 ? +(+row.promo_summa + +row.promo_summa_balance) / row.summa * 100 : 0
    },
    expenses_mp_unit({row}) {
        return +row.quantity !== 0 ? ((+row.delivery || 0) +
            (+row.delivery_unit || 0) +
            (+row.penalty || 0) +
            (-row.additional_payment || 0) +
            (+row.storage_cost || 0) +
            (+row.storage_fee_item || 0) +
            (+row.cost_acceptance || 0)) / row.quantity : 0
    },
    expenses_mp_percent({row}) {
        return +row.summa !== 0 ? ((+row.delivery || 0) +
            (+row.delivery_unit || 0) +
            (+row.penalty || 0) +
            (-row.additional_payment || 0) +
            (+row.storage_cost || 0) +
            (+row.storage_fee_item || 0) +
            (+row.cost_acceptance || 0)) / row.summa * 100 : 0
    },
    delivery_unit({row}) {
        return +row.quantity !== 0 ? row.delivery / row.quantity : 0
    },
}
