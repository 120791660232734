module.exports = {
    getToDay(days) {
        const date = new Date();
        if (days) {
            date.setDate(date.getDate() + days);
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    },
}