//paidStorageKgvp     - fbo
//kgvpMarketplace     - fbs
//kgvpSupplier        - dbs
//kgvpSupplierExpress - express

const resolveTypDelivery = {
    fbo: 'kgvp_marketplace',
    fbs: 'kgvp_supplier',
    dbs: 'paid_storage_kgvp',
    express: 'kgvp_supplier_express',
}

module.exports = {

    volume({row}) {
        return row.length * row.width * row.height / 1000
    },

    sales_plan({row, val}) {
        row.sales_plan = val
        if (row.discounted_price) {
            row.amount_sales = Math.round(val / +row.discounted_price);
        } else row.discounted_price = 0
    },

    ransom_percent({row, val}) {
        if (row.parent_id === 1) {
            row.ransom_percent = 40
        } else row.ransom_percent = 90
        return row.ransom_percent;
    },

    product_turnover({row, val}) {
        row.product_turnover = val;
        return row.product_turnover;
    },

    storage_over_turnover({row, val}) {
        //box_storage_base
        //box_storage_liter
        if (row.box_storage_base) {
            let first = 1 * row.volume * row.box_storage_base || 0
            let second = (row.volume - 1) * row.box_storage_liter || 0
            row.storage_over_turnover = Math.round((first + second) * row.product_turnover);
        } else {
            row.storage_over_turnover = Math.round((row.volume * (row.box_storage_liter || 0)) * row.product_turnover);
        }
        return row.storage_over_turnover
    },

    purchase_price_unit({row, val}) {
        row.purchase_price_unit = val
        row.purchase_price_total = Math.round(val * row.amount_sales)
    },

    purchase_price_total({row, val}) {
        row.purchase_price_total = val
        if (row.amount_sales) {
            row.purchase_price_unit = Math.round(val / row.amount_sales)
        } else row.purchase_price_unit = 0
    },

    delivery_calc_total({row, val}) {
        row.delivery_calc_total = val
        if (row.amount_sales) {
            row.delivery_calc_unit = Math.round(val / row.amount_sales)
            return row.delivery_calc_unit
        }
        row.delivery_calc_unit = 0
        return 0
    },

    delivery_calc_unit({row, val}) {
        row.delivery_calc_unit = val
        row.delivery_calc_total = Math.round(+val * row.amount_sales)
    },

    prepend_total({row, val}) {
        if (row.amount_sales) {
            row.prepend_unit = Math.round(+val / row.amount_sales)
            return row.prepend_unit
        }
        row.prepend_unit = 0
        return 0
    },

    prepend_unit({row, val}) {
        row.prepend_unit = val
        row.prepend_total = Math.round(+val * row.amount_sales)
    },

    cost_price({row, val}) {
        row.cost_price = Math.round((+row.purchase_price_total || 0) + (+row.delivery_calc_total || 0) + (+row.prepend_total || 0));
        return row.cost_price
    },

    cost_price_unit({row, val}) {
        console.log('cost_price_unit', row.nmID)
        if (row.amount_sales) {
            row.cost_price_unit = Math.round(row.cost_price / row.amount_sales)
            return row.cost_price_unit
        }
        row.cost_price_unit = 0
        return 0
    },

    cost_price_percent({row, val}) {
        if (row.sales_plan) {
            row.cost_price_percent = Math.round(row.cost_price / row.sales_plan * 100)
            return row.cost_price_percent
        }
        row.cost_price_percent = 0
        return 0
    },

    promo_total({row, val}) {
        if (row.promo_percent) {
            row.promo_total = Math.round(row.sales_plan / 100 * row.promo_percent);
            return row.promo_total
        }
        row.promo_total = 0
        return 0
    },

    promo_unit({row, val}) {
        if (row.promo_percent && row.amount_sales) {
            row.promo_unit = Math.round((row.sales_plan / 100 * row.promo_percent) / row.amount_sales);
            return row.promo_unit
        }
        row.promo_unit = 0
        return 0
    },

    promo_percent({row, val}) {
        row.promo_percent = val
    },

    wb_commission_unit({row}) {
        row.wb_commission_percent = row[resolveTypDelivery[row.variant_delivery]];
        row.wb_commission_unit = Math.round((row.seller_price * row[resolveTypDelivery[row.variant_delivery]] || 0) / 100);
        return row.wb_commission_unit
    },

    logistics_tariff({row}) {
        if (row.box_delivery_base) {
            let first = 1 * row.volume * row.box_delivery_base || 0
            let second = (row.volume - 1) * row.box_delivery_liter || 0
            row.logistics_tariff = Math.round(first + second);
        } else {
            row.logistics_tariff = Math.round(row.volume * (row.box_delivery_liter || 0));
        }
        return row.logistics_tariff
    },

    logistics_with_ransom({row}) {
        return row.logistics_tariff + row.logistics_tariff / 100 * (100 - row.ransom_percent)
    },

    storage_per_day({row}) {
        return row.box_storage_base
    },

    expenses_mp_sum({row}) {
        row.expenses_mp_sum = (row.expenses_mp_unit || 0) * row.amount_sales;
        return row.expenses_mp_sum;
    },

    expenses_mp_unit({row}) {
        row.expenses_mp_unit = (row.wb_commission_unit || 0) + (row.logistics_with_ransom || 0) + (row.storage_over_turnover || 0);
        return row.expenses_mp_unit;
    },

    expenses_mp_percent({row}) {
        if (row.seller_price) {
            row.expenses_mp_percent = Math.round((row.wb_commission_unit || 0) / row.seller_price * 100);
            return row.expenses_mp_percent;
        } else {
            row.expenses_mp_percent = 0
            return row.expenses_mp_percent;
        }
    },

    receipt_unit({row}) {
        row.receipt_unit = (+row.expenses_mp_unit || 0) + (+row.expenses_mp_unit || 0) + (+row.promo_unit || 0);
        return row.receipt_unit;
    },

    receipt_part({row}) {
        row.receipt_part = (row.receipt_unit || 0) * row.amount_sales;
        return row.receipt_part;
    },

    receipt_percent({row}) {
        if (row.seller_price) {
            row.receipt_percent = Math.round((row.receipt_unit || 0) / row.seller_price * 100);
            return row.receipt_percent;
        } else {
            row.receipt_percent = 0
            return row.receipt_percent;
        }
    },

    val_value_part({row}) {
        row.val_value_part = Math.round(row.val_value_unit * row.amount_sales);
        return row.val_value_part;
    },

    val_value_unit({row}) {
        row.val_value_unit = Math.round(row.receipt_unit  - row.dues_unit);
        return row.val_value_unit;
    },

    val_value_percent({row}) {
        if (row.seller_price) {
            row.val_value_percent = Math.round((row.val_value_unit || 0) / row.seller_price * 100);
            return row.val_value_percent;
        } else {
            row.val_value_percent = 0
            return row.val_value_percent;
        }
    },

    dues_unit({row}) {
        if (row.amount_sales) {
            row.dues_unit = Math.round((row.dues || 0) / row.amount_sales);
            return row.dues_unit;
        } else {
            row.dues_unit = 0
            return row.dues_unit;
        }
    },

    dues({row}) {
        row.dues = Math.round(row.receipt_part / 100 * (row.dues_percent || 6));
        return row.dues
    },
}