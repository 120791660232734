<template>
  <div>
    <apexchart v-if="this.stock.length" :ref="'totalValuesChart'" width="550" type="donut" :options="chartOptions"
               :series="seriesData">
    </apexchart>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";
import {mapState} from "vuex";
import unitReportCalculateHr from "@/libs/unitReportCalculateHr";

function formatCurrency(value) {
  let val = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(value);
  return val.split(',')[0]
}

export default {
  name: "totalValuesChart",
  components: {
    apexchart: ApexCharts
  },
  computed: {
    ...mapState("unitReportScheme", ["stock"]),
    ...mapState("unitReport", ['unitReportRows', 'unitReportSum']),

    seriesData() {
      return [
        this.unitReportSum.sales_cost_price,
        unitReportCalculateHr.expenses_mp_sum({unitReportSum: this.unitReportSum}),
        unitReportCalculateHr.promo_total({unitReportSum: this.unitReportSum}),
        this.unitReportSum.dues,
        this.unitReportSum.summa_val_value
      ];
    },

    getLabels() {
      return [
        'Себес',
        'Расходы МП',
        'Продвижение',
        'Налоги',
        'Вал прибыль',
      ]
    },

    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        tooltip: {
          y: {
            // Используем функцию formatCurrency для форматирования значения
            formatter: (val) => formatCurrency(val),
          },
        },
        labels: this.getLabels,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: false,  // Скрыть лейблы
                },
                value: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: 'rgba(98,95,95,0.7)',
                  formatter: function (val) {
                    return formatCurrency(val);  // Отображаем только значение
                  }
                },
                total: {
                  show: false
                }
              }
            }
          }
        },
        colors: [
          "#72a2ee",
          "#89d2ae",
          "#ffce71",
          "#f391a0",
          "#b4a7d4",
          "#a3c1f2",
          "#f0bfb6",
        ],
        legend: {
          position: 'right', // Переносим легенду вправо
          width: 220, // Ограничиваем ширину легенды
        },
        title: {
          text: "Структура продаж",
          align: "center"
        },
      };
    }
  },

  watch: {}
};
</script>

<style scoped>

</style>